import {qspost} from '@/plugins/axios'

/*
 * 返回 word 转html的结果地址
 * @constructor jack
 * */
export const getOfferHtml = (fileName) => qspost('/index/Tword/getOfferHtml', {fileName})


/*
 * 获取用户基本信息
 * @constructor jack
 * */
export const getBaseDataByOfferId = (offerId) => qspost('/index/Other/getBaseDataByOfferId', {offerId})


/*
 * 更新用户基本信息
 * @constructor jack
 * */
export const updateStaffInfoData = (offerId, leixing, xingming, nicheng, xingbie, shoujihao,chushengnianyueri, shenfenzhenghao, neibuyouxiang, youxiang, jiatingdizhi, weixinhao, biyeyuanxiao, zaixiaozhuanye, zuigaoxueli, biyeshijian, xianjudizhi, zuizaoruzhishijian, jinjirenxingming, jinjirendianhua, yubenrenguanxi, kaihuhang, zhihangmingcheng, yinhangkahao) => qspost('/index/Staff/updateStaffInfoData', {
    offerId,
    leixing,
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    neibuyouxiang,
    youxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao
})
/*
 * 更新用户基本信息
 * @constructor jack
 * */
export const updateStaffBaseData = (
    offerId,
    leixing,
    ruzhiriqi,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    workType,
    paymentType,
    jibenyuexin,
    contractStartDate,
    contractEndDate,
    nonFixedTermStartDate,
    workStartDate,
    workEndDate,
    offer_file,
    tongzhi_file,
    hetong_file,
    baomi_file,
) => qspost('/index/Staff/updateStaffBaseData', {
    offerId,
    leixing,
    ruzhiriqi,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    workType,
    paymentType,
    jibenyuexin,
    contractStartDate,
    contractEndDate,
    nonFixedTermStartDate,
    workStartDate,
    workEndDate,
    offer_file,
    tongzhi_file,
    hetong_file,
    baomi_file,
})
export const updateStaffBaseData2 = (
    offerId,
    leixing,
    ruzhiriqi,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    gongzuofangshi,
    internJoinDate,
    internResignDate,
    internWeeklyDays,
    offer_file,
    tongzhi_file,
    xieyi_file,
) => qspost('/index/Staff/updateStaffBaseData', {
    offerId,
    leixing,
    ruzhiriqi,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    gongzuofangshi,
    internJoinDate,
    internResignDate,
    internWeeklyDays,
    offer_file,
    tongzhi_file,
    xieyi_file,
})
export const updateStaffBaseData3 = (offerId,
                                     leixing,
                                     ruzhiriqi,
                                     gangweimingcheng,
                                     lishubumen,
                                     zhijieshangji,
                                     jianzhixinchou,
                                     ConsultantWorkType,
                                     gongzuomoshi,
                                     ConsultantJoinDate,
                                     ConsultantResignDate,
                                     offer_file,
                                     tongzhi_file,
                                     xieyi_file,) => qspost('/index/Staff/updateStaffBaseData', {
    offerId,
    leixing,
    ruzhiriqi,
    gangweimingcheng,
    lishubumen,
    zhijieshangji,
    jianzhixinchou,
    ConsultantWorkType,
    gongzuomoshi,
    ConsultantJoinDate,
    ConsultantResignDate,
    offer_file,
    tongzhi_file,
    xieyi_file,
})
/*
 * 添加 续签合同
 * @constructor jack
 * */
export const createXuQianHeTong = (offerId, file) => qspost('/index/Staff/createXuQianHeTong', {
    offerId, file
})

/*
 * 删除 续签合同
 * @constructor jack
 * */
export const delXuQianHeTong = (id) => qspost('/index/Staff/delXuQianHeTong', {
    id
})

/*
 * 导入全职员工信息
 * @constructor jack
 * */
export const fullTimeImport = (
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    neibuyouxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    jibenyuexin,
    qianshunianxian,
    ruzhiriqi,
    gongzuodidian,
    contractNo,
    contractType,
    workType,
    contractStartDate,
    contractEndDate,
    nonFixedTermStartDate,
    workStartDate,
    workEndDate,
    probationStartDate,
    probationEndDate,
    shiyongqiyuexin,
    shiyongqigongzi,
    paymentType,
    appendixNubmer,
    contractSignDate,
    cASignDate,
    offer_file,
    tongzhi_file,
    hetong_file,
    baomi_file,) => qspost('/index/Importstaff/fullTimeImport', {
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    neibuyouxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    jibenyuexin,
    qianshunianxian,
    ruzhiriqi,
    gongzuodidian,
    contractNo,
    contractType,
    workType,
    contractStartDate,
    contractEndDate,
    nonFixedTermStartDate,
    workStartDate,
    workEndDate,
    probationStartDate,
    probationEndDate,
    shiyongqiyuexin,
    shiyongqigongzi,
    paymentType,
    appendixNubmer,
    contractSignDate,
    cASignDate,
    offer_file,
    tongzhi_file,
    hetong_file,
    baomi_file,

})
export const practiceImport = (
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    neibuyouxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    shixizhouqi,
    ruzhiriqi,
    gongzuodidian,
    internJoinDate,
    internResignDate,
    internWeeklyDays,
    gongzuofangshi,
    offer_file,
    tongzhi_file,
    xieyi_file,
    ) => qspost('/index/Importstaff/practiceImport', {
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    neibuyouxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    shixizhouqi,
    ruzhiriqi,
    gongzuodidian,
    internJoinDate,
    internResignDate,
    internWeeklyDays,
    gongzuofangshi,
    offer_file,
    tongzhi_file,
    xieyi_file,
})
export const partTimeImport = (
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    neibuyouxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    ConsultantPeriod,
    jianzhixinchou,
    ruzhiriqi,
    gongzuodidian,
    ConsultantJoinDate,
    ConsultantResignDate,
    ConsultantWorkType,
    gongzuomoshi,
    offer_file,
    tongzhi_file,
    xieyi_file,
    ) => qspost('/index/Importstaff/partTimeImport', {
    xingming,
    nicheng,
    xingbie,
    shoujihao,chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    neibuyouxiang,
    jiatingdizhi,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao,
    lishubumen,
    gangweimingcheng,
    zhijieshangji,
    ConsultantPeriod,
    jianzhixinchou,
    ruzhiriqi,
    gongzuodidian,
    ConsultantJoinDate,
    ConsultantResignDate,
    ConsultantWorkType,
    gongzuomoshi,
    offer_file,
    tongzhi_file,
    xieyi_file,
})




