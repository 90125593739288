<template>
  <div class="wrapper padding-lr-sm">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" v-if="staffInfoData">
        <template slot="title">
          <div class="flex align-center justify-between">
            <h3 class="margin-right">一、基本信息</h3>
            <el-button class="template-btn" size="mini" type="primary" @click.stop="infoVisible = true">
              修改
            </el-button>
          </div>
        </template>
        <el-descriptions :column="2" border>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">员工姓名</template>
            {{ staffInfoData.xingming }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">英文/昵称</template>
            {{ staffInfoData.nicheng }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">性别</template>
            <span v-if="staffInfoData.xingbie === 1">男</span>
            <span v-if="staffInfoData.xingbie === 2">女</span>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">身份证/通行证</template>
            {{ staffInfoData.shenfenzhenghao }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">手机</template>
            {{ staffInfoData.shoujihao }}
          </el-descriptions-item>
            <el-descriptions-item :labelStyle="labelStyle">
                <template slot="label">出生年月日</template>
                {{ staffInfoData.chushengnianyueri }}
            </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">个人微信</template>
            {{ staffInfoData.weixinhao }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">工作邮箱</template>
            {{ staffInfoData.neibuyouxiang }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">个人邮箱</template>
            {{ staffInfoData.youxiang }}
          </el-descriptions-item>

          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">家庭地址</template>
            {{ staffInfoData.jiatingdizhi }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">现居住地址</template>
            {{ staffInfoData.xianjudizhi }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">毕业学校</template>
            {{ staffInfoData.biyeyuanxiao }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">在校专业</template>
            {{ staffInfoData.zaixiaozhuanye }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">最高学历</template>
            {{ staffInfoData.zuigaoxueli }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">毕业时间</template>
            {{ staffInfoData.biyeshijian }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">银行卡开户行</template>
            {{ staffInfoData.kaihuhang }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">紧急联系人姓名</template>
            {{ staffInfoData.jinjirenxingming }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">开户行支行名称</template>
            {{ staffInfoData.zhihangmingcheng }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">与本人关系</template>
            {{ staffInfoData.yubenrenguanxi }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">银行卡号</template>
            {{ staffInfoData.yinhangkahao }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">紧急联系人电话</template>
            {{ staffInfoData.jinjirendianhua }}
          </el-descriptions-item>


        </el-descriptions>
      </el-collapse-item>
      <el-collapse-item name="2" v-if="offerSendData">
        <template slot="title">
          <div class="flex align-center justify-between">
            <h3 class="margin-right">二、在职信息</h3>
            <el-button class="template-btn" size="mini" type="primary" @click.stop="workVisible = true">
              修改
            </el-button>
          </div>
        </template>
        <el-descriptions :column="2" border>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">类型/状态</template>
            {{ type === 1 ? '全职' : type === 2 ? '实习' : '兼职' }}

            <span v-if="zaizhilizhi === 1">(在职)</span>
            <span v-if="zaizhilizhi === 2">(离职中)</span>
            <span v-if="zaizhilizhi === 3">(已离职)</span>


          </el-descriptions-item>

          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">入职日期</template>
            {{ staffInfoData.ruzhiriqi }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">隶属部门</template>
            {{ staffInfoData.lishubumen }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">岗位</template>
            {{ staffInfoData.gangweimingcheng }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">直属领导</template>
            {{ offerSendData.zhijieshangjiCn ? offerSendData.zhijieshangjiCn : '--(已离职)' }}
          </el-descriptions-item>

          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">工时制方式</template>
            <span v-if="Number(staffInfoData.workType) === 1">工时制方式</span>
            <span v-if="Number(staffInfoData.workType) === 2">任务目标制</span>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">工资形式</template>
            <span v-if="Number(staffInfoData.paymentType) === 1">计时工资</span>
            <span v-if="Number(staffInfoData.paymentType) === 2">计件工资</span>
            <span v-if="Number(staffInfoData.paymentType) === 2">其他工资形式</span>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">月薪</template>
            {{ staffInfoData.jibenyuexin }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">合同编号</template>
            {{ staffInfoData.contractNo }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">合同类型</template>
            <span v-if="Number(staffInfoData.contractType) === 1">固定期限合同</span>
            <span v-if="Number(staffInfoData.contractType) === 2">无固定期限合同</span>
            <span v-if="Number(staffInfoData.contractType) === 3">工作任务期限合同</span>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">劳动合同开始时间
            </template>
            {{ staffInfoData.contractStartDate }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">劳动合同结束时间
            </template>
            {{ staffInfoData.contractEndDate }}
          </el-descriptions-item>


          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">Offer Letter（附件）</template>
            <el-link :href="openFile + staffInfoData.offer_file" target="_blank"
                     v-if="staffInfoData.offer_file">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">入职通知（附件）</template>
            <el-link :href="openFile + staffInfoData.tongzhi_file" target="_blank"
                     v-if="staffInfoData.tongzhi_file">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">
              劳动合同（附件）
            </template>
            <el-link :href="openFile + staffInfoData.hetong_file" target="_blank"
                     v-if="staffInfoData.hetong_file">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">保密协议（附件）</template>
            <el-link :href="openFile + staffInfoData.baomi_file" target="_blank"
                     v-if="staffInfoData.baomi_file">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>


        </el-descriptions>
        <el-descriptions :column="1" border>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">续签合同（附件）</template>


            <div class="flex align-center">
              <el-upload
                  ref="upBtn"
                  class="upload-demo margin-right"
                  :action="actions"
                  :data="{token}"
                  :on-success="handleSuccess"
                  :limit="1"
                  :show-file-list="false"
              >
                <el-button size="mini" type="warning">上传续签合同</el-button>
              </el-upload>


              <el-timeline v-if="continueContractList && continueContractList.length>0">
                <el-timeline-item v-for="item in continueContractList" :key="item.id">
                  <span class="margin-right">{{ item.create_time }}上传</span>
                  <el-link :href="openFile + item.file_url" target="_blank" class="margin-right">
                    <el-button class="template-btn" size="mini" type="primary">点击下载查看
                    </el-button>
                  </el-link>

                  <el-popconfirm
                      title="确定要删除该文件吗？"
                      @confirm="delXqFile(item.id)"
                  >

                    <el-button type="danger" icon="el-icon-delete" circle size="mini"
                               slot="reference"></el-button>

                  </el-popconfirm>


                </el-timeline-item>
              </el-timeline>

            </div>


          </el-descriptions-item>
        </el-descriptions>
      </el-collapse-item>
      <el-collapse-item name="3" v-if="departData">
        <template slot="title"><h3>三、离职信息</h3></template>
        <el-descriptions :column="2" border>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">提交离职报告时间</template>
            {{ departData.tijiaoriqi }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职时间</template>
            {{ departData.lizhiriqi }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职当月应工作天数</template>
            {{ departData.yinggongzuotianshu }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职当月实际工作天数</template>
            {{ departData.shijigongzuotianshu }}
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职原因</template>
            <span v-if="Number(departData.lizhiyuanying) === 1">主动请辞</span>
            <span v-if="Number(departData.lizhiyuanying) === 2">合同到期员工放弃续约</span>
            <span v-if="Number(departData.lizhiyuanying) === 3">辞退</span>
            <span v-if="Number(departData.lizhiyuanying) === 4">其他({{
                departData.lizhiyuanying_qita
              }})</span>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职协议（附件）</template>
            <el-link :href="openFile + departData.xieyi_state" target="_blank"
                     v-if="departData.xieyi_state">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职交接表（附件）</template>
            <el-link :href="openFile + departData.jiaojiebiao_state" target="_blank"
                     v-if="departData.jiaojiebiao_state">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>

          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">薪资核算单</template>
            <el-link :href="openFile + departData.xinchou_state" target="_blank"
                     v-if="departData.xinchou_state">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>
          <el-descriptions-item :labelStyle="labelStyle">
            <template slot="label">离职证明</template>
            <el-link :href="openFile + departData.zhengming_state" target="_blank"
                     v-if="departData.zhengming_state">
              <el-button class="template-btn" size="mini" type="primary">下载</el-button>
            </el-link>
          </el-descriptions-item>

        </el-descriptions>
      </el-collapse-item>
    </el-collapse>
    <el-dialog
        title="基本信息修改"
        :visible.sync="infoVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="1200px"
    >
      <el-form :model="infoForm"
               label-width="140px"
               :rules="infoRules" ref="infoForm" class="add-form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名" prop="xingming">
              <el-input v-model="infoForm.xingming" placeholder="请填写姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="昵称/英文" prop="nicheng">
              <el-input v-model="infoForm.nicheng" placeholder="请填写昵称/英文"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="xingbie">
              <el-select v-model="infoForm.xingbie" placeholder="请选择性别" class="w-all">
                <el-option
                    v-for="item in sexList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机" prop="shoujihao">
              <el-input v-model="infoForm.shoujihao" placeholder="请填写手机"></el-input>
            </el-form-item>
          </el-col>
            <el-col :span="12">
                <el-form-item label="出生年月日" prop="chushengnianyueri">
                    <el-date-picker align="center"
                                    class="w-all"
                                    value-format="yyyy-MM-dd"
                                    v-model="infoForm.chushengnianyueri"
                                    type="date"
                                    placeholder="请填写出生年月日">
                    </el-date-picker>
                </el-form-item>
            </el-col>
          <el-col :span="12">
            <el-form-item label="身份证/通行证" prop="shenfenzhenghao">
              <el-input v-model="infoForm.shenfenzhenghao" placeholder="请填写身份证/通行证"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个人微信" prop="weixinhao">
              <el-input v-model="infoForm.weixinhao" placeholder="请填写个人微信"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作邮箱" prop="neibuyouxiang">
              <el-input v-model="infoForm.neibuyouxiang" placeholder="请填写工作邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个人邮箱" prop="youxiang">
              <el-input v-model="infoForm.youxiang" placeholder="请填写个人邮箱"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="家庭地址" prop="jiatingdizhi">
              <el-input v-model="infoForm.jiatingdizhi" placeholder="请填写家庭地址"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="毕业学校" prop="biyeyuanxiao">
              <el-input v-model="infoForm.biyeyuanxiao" placeholder="请填写毕业学校"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="在校专业" prop="zaixiaozhuanye">
              <el-input v-model="infoForm.zaixiaozhuanye" placeholder="请填写在校专业"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最高学历" prop="zuigaoxueli">
              <el-input v-model="infoForm.zuigaoxueli" placeholder="请填写最高学历"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="毕业时间" prop="biyeshijian">
              <el-date-picker align="center"
                              class="w-all"
                              v-model="infoForm.biyeshijian"
                              type="date"
                              placeholder="请填写毕业时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现居住地址" prop="xianjudizhi">
              <el-input v-model="infoForm.xianjudizhi" placeholder="请填写现居住地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最早入职时间" prop="zuizaoruzhishijian">
              <el-date-picker align="center"
                              class="w-all"
                              v-model="infoForm.zuizaoruzhishijian"
                              type="date"
                              placeholder="请填写最早入职时间">
              </el-date-picker>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡开户行" prop="kaihuhang">
              <el-input v-model="infoForm.kaihuhang" placeholder="请填写银行卡开户行"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人姓名" prop="jinjirenxingming">
              <el-input v-model="infoForm.jinjirenxingming" placeholder="请填写紧急联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户行支行名称" prop="zhihangmingcheng">
              <el-input v-model="infoForm.zhihangmingcheng" placeholder="请填写开户行支行名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="紧急联系人电话" prop="jinjirendianhua">
              <el-input v-model="infoForm.jinjirendianhua" placeholder="请填写紧急联系人电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号" prop="yinhangkahao">
              <el-input v-model="infoForm.yinhangkahao" placeholder="请填写银行卡号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="与本人关系" prop="yubenrenguanxi">
              <el-input v-model="infoForm.yubenrenguanxi" placeholder="请填写与本人关系"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-form-item label-width="0" class="flex justify-around">
          <div>
            <el-button class="submit-btn" type="primary" plain @click=" infoVisible = false">取消
            </el-button>
            <el-button class="submit-btn" type="primary" @click="submitForm('infoForm')">保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
        title="在职信息修改"
        :visible.sync="workVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="1200px"
    >
      <el-form :model="workForm"
               label-width="180px"
               :rules="workRules" ref="workForm" class="add-form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="岗位" prop="gangweimingcheng">
              <el-input v-model="workForm.gangweimingcheng" placeholder="请输入岗位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="隶属部门" prop="lishubumen">
              <el-input v-model="workForm.lishubumen" placeholder="请输入隶属部门"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="直接上级" prop="zhijieshangji">
              <el-select v-model="workForm.zhijieshangji" placeholder="请选择直接上级" class="w-all">
                <el-option
                    v-for="item in superior"
                    :key="item.id"
                    :label="item.xingming"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月薪"
                          prop="jibenyuexin">
              <el-input v-model="workForm.jibenyuexin" placeholder="请输入月薪" type="number">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工时制方式 " prop="workType">
              <el-select v-model="workForm.workType" placeholder="请选择工时制方式 " class="w-all">
                <el-option
                    v-for="item in  workTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工资形式 " prop="paymentType">
              <el-select v-model="workForm.paymentType" placeholder="请选择工资形式 " class="w-all">
                <el-option
                    v-for="item in paymentTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">

            <el-form-item label="入职日期" prop="ruzhiriqi">
              <el-date-picker
                  align="center"
                  class="w-all"
                  v-model="workForm.ruzhiriqi"
                  type="date"
                  placeholder="请选择入职日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同类型 " prop="contractType">
              <el-select v-model="workForm.contractType" placeholder="请选择合同类型 " disabled class="w-all">
                <el-option
                    v-for="item in contractTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="workForm.contractType === 1" :span="12">
            <el-form-item
                label="固定期限合同开始时间"
                prop="contractStartDate">
              <el-date-picker
                  align="center"
                  class="w-all"
                  v-model="workForm.contractStartDate"
                  type="date"
                  placeholder="请选择开始日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="workForm.contractType === 1" :span="12">
            <el-form-item
                label="固定期限合同结束时间"
                prop="contractStartDate">
              <el-date-picker
                  align="center"
                  class="w-all"
                  v-model="workForm.contractEndDate"
                  type="date"
                  placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="workForm.contractType === 2" :span="12">
            <el-form-item
                label="无固定期限开始时间 "
                prop="nonFixedTermStartDate">
              <el-date-picker
                  align="center"
                  class="w-all"
                  v-model="workForm.nonFixedTermStartDate"
                  type="date"
                  placeholder="请选择开始日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="workForm.contractType === 3" :span="12">
            <el-form-item
                label="工作任务期限开始合同时间"
                prop="contractStartDate">
              <el-date-picker
                  align="center"
                  class="w-all"
                  v-model="workForm.workStartDate"
                  type="date"
                  placeholder="请选择开始日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="workForm.contractType === 3" :span="12">
            <el-form-item
                label="工作任务期限开始合同时间 "
                prop="contractStartDate">
              <el-date-picker
                  align="center"
                  class="w-all"
                  v-model="workForm.workEndDate"
                  type="date"
                  placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Offer Letter（附件）" prop="offer_file">
              <el-button class="template-btn margin-right" size="mini" type="primary"
                         @click="chooseFile(1)">上传
              </el-button>
              <el-link v-if="workForm.offer_file" :href="openFile + workForm.offer_file" target="_blank">
                已上传附件(点击查看)
              </el-link>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="入职通知（附件）" prop="tongzhi_file">
              <el-button class="template-btn margin-right" size="mini" type="primary"
                         @click="chooseFile(2)">上传
              </el-button>
              <el-link v-if="workForm.tongzhi_file" :href="openFile + workForm.tongzhi_file"
                       target="_blank">
                已上传附件(点击查看)
              </el-link>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="type === 1">
            <el-form-item label="劳动合同（附件）" prop="hetong_file">
              <el-button class="template-btn margin-right" size="mini" type="primary"
                         @click="chooseFile(3)">上传
              </el-button>

              <el-link v-if="workForm.hetong_file" :href="openFile + workForm.hetong_file"
                       target="_blank">
                已上传附件(点击查看)
              </el-link>

            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="type === 1">
            <el-form-item label="保密协议（附件）" prop="baomi_file">
              <el-button class="template-btn margin-right" size="mini" type="primary"
                         @click="chooseFile(4)">上传
              </el-button>
              <el-link v-if="workForm.baomi_file" :href="openFile + workForm.baomi_file" target="_blank">
                已上传附件(点击查看)
              </el-link>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="type === 2">
            <el-form-item label="实习协议（附件）" prop="xieyi_file">
              <el-button class="template-btn margin-right" size="mini" type="primary"
                         @click="chooseFile(5)">上传
              </el-button>
              <el-link v-if="workForm.xieyi_file" :href="openFile + workForm.xieyi_file" target="_blank">
                已上传附件(点击查看)
              </el-link>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="type === 3">
            <el-form-item label="兼职协议（附件）" prop="xieyi_file">
              <el-button class="template-btn margin-right" size="mini" type="primary"
                         @click="chooseFile(5)">上传
              </el-button>
              <el-link v-if="workForm.xieyi_file" :href="openFile + workForm.xieyi_file" target="_blank">
                已上传附件(点击查看)
              </el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label-width="0" class="flex justify-around">
          <div>
            <el-button class="submit-btn" type="primary" plain @click=" workVisible = false">取消
            </el-button>
            <el-button class="submit-btn" type="primary" @click="submitWorkForm('workForm')">保存
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-upload
        style="display: none;"
        ref="fileBtn"
        class="upload-demo"
        :action="actions"
        :data="{token}"
        :on-success="handleFileSuccess"
        :limit="1"
        :show-file-list="false"

    >
      <i class="fileBtn"></i>
    </el-upload>

  </div>
</template>

<script>
import {getStaffShow} from "@/api/staff";
import {openFile, upFile} from "@/config";
import {shangJiList} from "@/api/entry";
import {sexList, workingMode, wayOfWorking, workTypeList, paymentTypeList, contractTypeList} from "@/utils/const";
import rules from "@/utils/rules";
import {zhDate} from "@/utils";
import {createXuQianHeTong, delXuQianHeTong, updateStaffBaseData, updateStaffInfoData} from "@/api/common";

export default {
  name: "fullTime",
  data() {
    return {
      type: 1,
      offerId: '',
      openFile: openFile,
      activeNames: ['1', '2', '3'],
      labelStyle: {'width': '200px!important', 'background': '#f0f4f8', 'text-align': 'justify'},
      staffInfoData: null,
      offerSendData: null,
      protocolData: null,
      departData: null,
      continueContractList: null,
      zaizhilizhi: 1,


      infoVisible: false,
      workVisible: false,

      sexList: sexList,
      infoForm: {
        xingming: '',
        nicheng: '',
        xingbie: '',
        shoujihao: '',
          chushengnianyueri: '',

        shenfenzhenghao: '',
        neibuyouxiang: '',
        youxiang: '',
        jiatingdizhi: '',
        weixinhao: '',
        biyeyuanxiao: '',
        zaixiaozhuanye: '',
        zuigaoxueli: '',
        biyeshijian: '',
        xianjudizhi: '',
        zuizaoruzhishijian: '',
        jinjirenxingming: '',
        jinjirendianhua: '',
        yubenrenguanxi: '',
        kaihuhang: '',
        zhihangmingcheng: '',
        yinhangkahao: '',
      },
      infoRules: {
        xingming: rules.mustInput,
        // nicheng: rules.mustInput,
        xingbie: rules.mustSelect,
        shoujihao: rules.phone,
        shenfenzhenghao: rules.mustInput,
        // youxiang: rules.email,
        // jiatingdizhi: rules.mustInput,
        // weixinhao: rules.mustInput,

        // biyeyuanxiao: rules.mustInput,
        // zaixiaozhuanye: rules.mustInput,
        // zuigaoxueli: rules.mustInput,
        // biyeshijian: rules.mustDate,
        // xianjudizhi: rules.mustInput,
        // zuizaoruzhishijian: rules.mustDate,
        // jinjirenxingming: rules.mustInput,
        // jinjirendianhua: rules.phone,
        // yubenrenguanxi: rules.mustInput,
        // kaihuhang: rules.mustInput,
        // zhihangmingcheng: rules.mustInput,
        // yinhangkahao: rules.mustInput,


      },
      workingMode: workingMode,
      wayOfWorking: wayOfWorking,
      workTypeList: workTypeList,
      paymentTypeList: paymentTypeList,
      contractTypeList: contractTypeList,
      superior: [],
      workForm: {
        ruzhiriqi: '',
        lishubumen: '',
        gangweimingcheng: '',
        zhijieshangji: '',
        workType: '',
        paymentType: '',
        jibenyuexin: '',
        contractType: '',
        contractStartDate: '',
        contractEndDate: '',
        nonFixedTermStartDate: '',
        workStartDate: '',
        workEndDate: '',
        offer_file: '',
        tongzhi_file: '',
        hetong_file: '',
        baomi_file: '',
      },
      workRules: {
        ruzhiriqi: rules.mustDate,
        lishubumen: rules.mustInput,
        gangweimingcheng: rules.mustInput,
        zhijieshangji: rules.mustSelect,
        workType: rules.mustSelect,
        paymentType: rules.mustSelect,

        jibenyuexin: rules.mustInput,
        contractType: rules.mustSelect,
        contractStartDate: rules.mustDate,
        contractEndDate: rules.mustDate,
        nonFixedTermStartDate: rules.mustDate,
        workStartDate: rules.mustDate,
        workEndDate: rules.mustDate,

      },
      upType: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    },
    zhijieshangjiCn() {
      let _index = this.superior.findIndex(item => item.id === Number(this.staffInfoData.zhijieshangji))
      return this.superior[_index].name
    }
  },
  created() {
    this.$emit("setIndex", '/admin/staffList', "员工信息", true);
    this.id = this.$route.params.id
    this.getStaffShow()
    this.shangJiList()
  },
  components: {},
  methods: {
    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },

    async getStaffShow() {
      const res = await getStaffShow(this.id)
      this.type = res.data.offerSendData.leixing
      this.offerId = res.data.offerSendData.offer_id
      this.staffInfoData = res.data.staffInfoData
      this.protocolData = res.data.protocolData
      this.offerSendData = res.data.offerSendData
      this.departData = res.data.departData
      this.zaizhilizhi = res.data.zaizhilizhi
      this.continueContractList = res.data.continueContractList


      this.infoForm = {
        xingming: res.data.staffInfoData.xingming,
        nicheng: res.data.staffInfoData.nicheng,
        xingbie: res.data.staffInfoData.xingbie,
        shoujihao: res.data.staffInfoData.shoujihao,
          chushengnianyueri: res.data.staffInfoData.chushengnianyueri,
        shenfenzhenghao: res.data.staffInfoData.shenfenzhenghao,
        neibuyouxiang: res.data.staffInfoData.neibuyouxiang,
        youxiang: res.data.staffInfoData.youxiang,
        jiatingdizhi: res.data.staffInfoData.jiatingdizhi,
        weixinhao: res.data.staffInfoData.weixinhao,
        biyeyuanxiao: res.data.staffInfoData.biyeyuanxiao,
        zaixiaozhuanye: res.data.staffInfoData.zaixiaozhuanye,
        zuigaoxueli: res.data.staffInfoData.zuigaoxueli,
        biyeshijian: res.data.staffInfoData.biyeshijian ? new Date(res.data.staffInfoData.biyeshijian) : '',
        xianjudizhi: res.data.staffInfoData.xianjudizhi,
        zuizaoruzhishijian: res.data.staffInfoData.zuizaoruzhishijian ? new Date(res.data.staffInfoData.zuizaoruzhishijian) : '',
        jinjirenxingming: res.data.staffInfoData.jinjirenxingming,
        jinjirendianhua: res.data.staffInfoData.jinjirendianhua,
        yubenrenguanxi: res.data.staffInfoData.yubenrenguanxi,
        kaihuhang: res.data.staffInfoData.kaihuhang,
        zhihangmingcheng: res.data.staffInfoData.zhihangmingcheng,
        yinhangkahao: res.data.staffInfoData.yinhangkahao,
      }

      this.workForm = {
        ruzhiriqi: new Date(res.data.staffInfoData.ruzhiriqi),
        lishubumen: res.data.staffInfoData.lishubumen,
        gangweimingcheng: res.data.staffInfoData.gangweimingcheng,
        zhijieshangji: Number(res.data.staffInfoData.zhijieshangji),
        workType: Number(res.data.staffInfoData.workType),
        paymentType: Number(res.data.staffInfoData.paymentType),
        jibenyuexin: res.data.staffInfoData.jibenyuexin,
        contractType: Number(res.data.staffInfoData.contractType),
        contractStartDate: res.data.staffInfoData.contractStartDate ? new Date(res.data.staffInfoData.contractStartDate) : '',
        contractEndDate: res.data.staffInfoData.contractEndDate ? new Date(res.data.staffInfoData.contractEndDate) : '',
        nonFixedTermStartDate: res.data.staffInfoData.nonFixedTermStartDate ? new Date(res.data.staffInfoData.nonFixedTermStartDate) : '',
        workStartDate: res.data.staffInfoData.workStartDate ? new Date(res.data.staffInfoData.workStartDate) : '',
        workEndDate: res.data.staffInfoData.workEndDate ? new Date(res.data.staffInfoData.workEndDate) : '',


        offer_file: res.data.staffInfoData.offer_file,
        tongzhi_file: res.data.staffInfoData.tongzhi_file,
        hetong_file: res.data.staffInfoData.hetong_file,
        baomi_file: res.data.staffInfoData.baomi_file,

      }


    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureUp()
        }
      });
    },
    async sureUp() {
      let _state = await updateStaffInfoData(
          this.offerId,
          this.type,
          this.infoForm.xingming,
          this.infoForm.nicheng,
          this.infoForm.xingbie,
          this.infoForm.shoujihao,
          this.infoForm.chushengnianyueri,
          this.infoForm.shenfenzhenghao,
          this.infoForm.neibuyouxiang,
          this.infoForm.youxiang,
          this.infoForm.jiatingdizhi,
          this.infoForm.weixinhao,
          this.infoForm.biyeyuanxiao,
          this.infoForm.zaixiaozhuanye,
          this.infoForm.zuigaoxueli,
          this.infoForm.biyeshijian ? zhDate(new Date(this.infoForm.biyeshijian)) : '',
          this.infoForm.xianjudizhi,
          this.infoForm.zuizaoruzhishijian ? zhDate(new Date(this.infoForm.zuizaoruzhishijian)) : '',
          this.infoForm.jinjirenxingming,
          this.infoForm.jinjirendianhua,
          this.infoForm.yubenrenguanxi,
          this.infoForm.kaihuhang,
          this.infoForm.zhihangmingcheng,
          this.infoForm.yinhangkahao,
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        this.infoVisible = false
        await this.getStaffShow()
      } else {
        this.$message.error(_state.info)
      }
    },

    submitWorkForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureUpWork()
        }
      });
    },
    async sureUpWork() {
      let _state = await updateStaffBaseData(
          this.offerId,
          this.type,

          this.workForm.ruzhiriqi ?zhDate(new Date(this.workForm.ruzhiriqi)): '',
          this.workForm.lishubumen,
          this.workForm.gangweimingcheng,
          this.workForm.zhijieshangji,
          this.workForm.workType,
          this.workForm.paymentType,
          this.workForm.jibenyuexin,

          this.workForm.contractStartDate ? zhDate(new Date(this.workForm.contractStartDate)) : '',
          this.workForm.contractEndDate ? zhDate(new Date(this.workForm.contractEndDate)) : '',
          this.workForm.nonFixedTermStartDate ? zhDate(new Date(this.workForm.nonFixedTermStartDate)) : '',
          this.workForm.workStartDate ? zhDate(new Date(this.workForm.workStartDate)) : '',
          this.workForm.workEndDate ? zhDate(new Date(this.workForm.workEndDate)) : '',

          this.workForm.offer_file,
          this.workForm.tongzhi_file,
          this.workForm.hetong_file,
          this.workForm.baomi_file,
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        this.workVisible = false
        await this.getStaffShow()
      } else {
        this.$message.error(_state.info)
      }
    },

    // 上传文件
    chooseFile(num) {
      this.upType = num
      // console.log(document.querySelector('.fileBtn'))
      document.querySelector('.fileBtn').click()
    },


    handleFileSuccess(file) {
      if (file.status === 200) {
        if (this.upType === 1) this.workForm.offer_file = file.data
        if (this.upType === 2) this.workForm.tongzhi_file = file.data
        if (this.upType === 3) this.workForm.hetong_file = file.data
        if (this.upType === 4) this.workForm.baomi_file = file.data
        if (this.upType === 5) this.workForm.xieyi_file = file.data
        this.upType = ''
        this.$message.success('上传成功')
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['fileBtn'].clearFiles()
    },


    handleSuccess(file) {
      if (file.status === 200) {
        this.upXqFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upXqFile(url) {
      await createXuQianHeTong(this.offerId, url)
      this.$message.success('上传成功')
      await this.getStaffShow()
    },
    async delXqFile(id) {
      await delXuQianHeTong(id)
      this.$message.success('删除成功')
      await this.getStaffShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
</style>
