/*
* 验证身份证/通行证
* */
export function checkIdNum(rule, value, callback) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (!value) {
    return callback(new Error('身份证件号码不能为空'))
  } else if (!reg.test(value)) {
    return callback(new Error('身份证件号码不正确'))
  } else {
    callback()
  }
}
/*
* 验证手机号
* */
export function checkTelNum(rule, value, callback) {
  const reg = /^1[0-9]{10}$/;
  if (!value) {
    return callback(new Error('手机号码不能为空'))
  } else if (!reg.test(value)) {
    return callback(new Error('手机号码不正确'))
  } else {
    callback()
  }
}


export default {
  idCard: [{ required: true, validator: checkIdNum, trigger: 'blur' }],
  phone: [{ required: true, validator: checkTelNum, trigger: 'blur' }],
  mustInput:[{ required: true, message: '请填写相关信息', trigger: 'blur' }],
  mustDate:[ { type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
  mustArray:[ { type: 'array', required: true, message: '请选择', trigger: 'change' }],
  mustSelect:[ { required: true, message: '请选择相关信息', trigger: 'change' }],
  email:[
    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
  ]
}
