import {qspost, qspostfile} from '@/plugins/axios'

/*
 * offer主列表
 * @constructor jack
 * */
export const getAdminOfferList = (page, limit, searchName, staff_type, hetong_state) => qspost('/index/Offer/getAdminOfferList', {
  page,
  limit,
  searchName, staff_type, hetong_state
})


/*
 * 直接上级列表
 * @constructor jack
 * */
export const shangJiList = () => qspost('/index/Offer/shangJiList', {})
/*
 * 获取offer 详情
 * @constructor jack
 * */
export const getOfferShow = (id) => qspost('/index/Offer/getOfferShow', {id})
/*
 * 获取简历 详情
 * @constructor jack
 * */
export const resumeList = (offerId) => qspost('/index/Offer/resumeList', {offerId})

//  下载offer的word文档
export const downLoadOfferWord = (offerId, wordUrl) => qspostfile('/index/Offer/downLoadOfferWord', {offerId, wordUrl})
/*
 * 回复offer附件上传
 * @constructor jack
 * */
export const replyEntryNoticeFile = (offerId, type, file) => qspost('/index/Offer/replyEntryNoticeFile', {
  offerId,
  type,
  file
})

/*
 * 简历附件上传
 * @constructor jack
 * */
export const resumeAdd = (offerId, file) => qspost('/index/Offer/resumeAdd', {
  offerId,
  file
})
/*
 * 简历附件上传
 * @constructor jack
 * */
export const resumeDel = (id) => qspost('/index/Offer/resumeDel', {
  id
})


/*
 * 新增 全职员工 的offer
 * @constructor jack
 * */
export const staffFullTimeOffer = (xingming, gangwei, lishubumen, zhijieshangji, jibenyuegongzi, buzhu, ruzhiriqi, hetongqixian, shiyongqi, shiyongqigongzi, gongzuodidian, fasongyouxaing, chaosongrenyuan, id
) => qspost('/index/Offer/staffFullTimeOffer', {
  xingming,
  gangwei,
  lishubumen,
  zhijieshangji,
  jibenyuegongzi,
  buzhu,
  ruzhiriqi,
  hetongqixian,
  shiyongqi,
  shiyongqigongzi,
  gongzuodidian,
  fasongyouxaing,
  chaosongrenyuan,
  id
})

/*
 * 新增 实习 offer
 * @constructor jack
 * */
export const staffPracticeOffer = (xingming, gangwei, lishubumen, zhijieshangji, gongzuofangshi, ruzhiriqi, shixizhouqi, baodaodizhi, fasongyouxaing, chaosongrenyuan, id) => qspost('/index/Offer/staffPracticeOffer', {
  xingming,
  gangwei,
  lishubumen,
  zhijieshangji,
  gongzuofangshi,
  ruzhiriqi,
  shixizhouqi,
  baodaodizhi,
  fasongyouxaing,
  chaosongrenyuan,
  id
})

/*
 * 新增 兼职 offer
 * @constructor jack
 * */
export const staffPartTimeOffer = (xingming, gangwei, lishubumen, zhijieshangji, gongzuofangshi, ruzhiriqi, jianzhixinchou, gongzuomoshi, gongzuodidian, ruzhididian, fasongyouxaing, chaosongrenyuan, id) => qspost('/index/Offer/staffPartTimeOffer', {
  xingming,
  gangwei,
  lishubumen,
  zhijieshangji,
  gongzuofangshi,
  ruzhiriqi,
  jianzhixinchou,
  gongzuomoshi,
  gongzuodidian,
  ruzhididian,
  fasongyouxaing,
  chaosongrenyuan,
  id
})

/*
 * 新增 兼职 offer
 * @constructor jack
 * */
export const delOffer = (offerId) => qspost('/index/Offer/delOffer', {offerId})

/*
 * 员工选择同意还是拒绝offer
 * @constructor jack
 * */
export const setOfferState = (offerId, offerState) => qspost('/index/Offer/setOfferState', {offerId, offerState})


/*
 * 完善 全职 员工信息
 * @constructor jack
 * */
export const perfectStaffFullTime = (offerId, xingming, nicheng, xingbie, shoujihao,chushengnianyueri,  shenfenzhenghao, youxiang, jiatingdizhi,youbian, weixinhao, biyeyuanxiao, zaixiaozhuanye, zuigaoxueli, biyeshijian, xianjudizhi, zuizaoruzhishijian, jinjirenxingming, jinjirendianhua, yubenrenguanxi, kaihuhang, zhihangmingcheng, yinhangkahao) => qspost('/index/Perfectbase/perfectStaffFullTime', {
  offerId,
  xingming,
  nicheng,
  xingbie,
  shoujihao,chushengnianyueri,
  shenfenzhenghao,
  youxiang,
  jiatingdizhi,youbian,
  weixinhao,
  biyeyuanxiao,
  zaixiaozhuanye,
  zuigaoxueli,
  biyeshijian,
  xianjudizhi,
  zuizaoruzhishijian,
  jinjirenxingming,
  jinjirendianhua,
  yubenrenguanxi,
  kaihuhang,
  zhihangmingcheng,
  yinhangkahao
})

/*
 * 完善 实习 员工信息
 * @constructor jack
 * */
export const perfectStaffPractice = (offerId, xingming, nicheng, xingbie, shoujihao,chushengnianyueri, shenfenzhenghao, youxiang, jiatingdizhi, youbian,weixinhao, biyeyuanxiao, zaixiaozhuanye, zuigaoxueli, biyeshijian, xianjudizhi, zuizaoruzhishijian, jinjirenxingming, jinjirendianhua, yubenrenguanxi, kaihuhang, zhihangmingcheng, yinhangkahao) => qspost('/index/Perfectbase/perfectStaffPractice', {
  offerId,
  xingming,
  nicheng,
  xingbie,
  shoujihao,
  chushengnianyueri,
  shenfenzhenghao,
  youxiang,
  jiatingdizhi,youbian,
  weixinhao,
  biyeyuanxiao,
  zaixiaozhuanye,
  zuigaoxueli,
  biyeshijian,
  xianjudizhi,
  zuizaoruzhishijian,
  jinjirenxingming,
  jinjirendianhua,
  yubenrenguanxi,
  kaihuhang,
  zhihangmingcheng,
  yinhangkahao
})

/*
 * 完善 兼职 员工信息
 * @constructor jack
 * */
export const perfectStaffPartTime = (offerId, xingming, nicheng, xingbie, shoujihao,chushengnianyueri,  shenfenzhenghao, youxiang, jiatingdizhi, youbian,weixinhao, biyeyuanxiao, zaixiaozhuanye, zuigaoxueli, biyeshijian, xianjudizhi, zuizaoruzhishijian, jinjirenxingming, jinjirendianhua, yubenrenguanxi, kaihuhang, zhihangmingcheng, yinhangkahao) => qspost('/index/Perfectbase/perfectStaffPartTime', {
  offerId,
  xingming,
  nicheng,
  xingbie,
  shoujihao,chushengnianyueri,
  shenfenzhenghao,
  youxiang,
  jiatingdizhi,youbian,
  weixinhao,
  biyeyuanxiao,
  zaixiaozhuanye,
  zuigaoxueli,
  biyeshijian,
  xianjudizhi,
  zuizaoruzhishijian,
  jinjirenxingming,
  jinjirendianhua,
  yubenrenguanxi,
  kaihuhang,
  zhihangmingcheng,
  yinhangkahao
})


/*
 * 获取员工信息 详情
 * @constructor jack
 * */
export const perfectStaffShow = (offerId, type) => qspost('/index/Perfectbase/perfectStaffShow', {offerId, type})

/*
 * 获取入职信息 详情
 * @constructor jack
 * */
export const entryNoticeShow = (offerId, type) => qspost('/index/Entrynotice/entryNoticeShow', {offerId, type})


/*
 * 回复入职通知附件上传
 * @constructor jack
 * */
export const replyNoticeFile = (offerId, type, file) => qspost('/index/Entrynotice/replyEntryNoticeFile', {
  offerId,
  type,
  file
})


/*
 * 全职员工入职通知新增、修改
 * @constructor jack
 * */
export const entryNoticeStaffFullTime = (offerId, xingming, zhiweimingcheng, lishubumen, zhijieshangji, shangjiweixin, shangjishouji, ruzhiriqi, gongzuodidian) => qspost('/index/Entrynotice/entryNoticeStaffFullTime', {
  offerId,
  xingming,
  zhiweimingcheng,
  lishubumen,
  zhijieshangji,
  shangjiweixin,
  shangjishouji,
  ruzhiriqi,
  gongzuodidian
})

/*
 * 实习生入职通知新增、修改
 * @constructor jack
 * */
export const entryNoticePractice = (offerId, xingming, zhiweimingcheng, lishubumen, shixizhouqi, internWeeklyDays, gongzuofangshi, zhijieshangji, shangjiweixin, shangjishouji, ruzhiriqi, gongzuodidian, internReplyDate) => qspost('/index/Entrynotice/entryNoticePractice', {
  offerId,
  xingming,
  zhiweimingcheng,
  lishubumen,
  shixizhouqi,
  internWeeklyDays,
  gongzuofangshi,
  zhijieshangji,
  shangjiweixin,
  shangjishouji,
  ruzhiriqi,
  gongzuodidian,
  internReplyDate,
})

/*
 * 兼职员工入职通知新增、修改
 * @constructor jack
 * */
export const entryNoticePartTime = (offerId, xingming, lishubumen, zhiweimingcheng, zhijieshangji, LeaderWechat, LeaderMobile, ruzhiriqi, ConsultantWorkLocation, ConsultantReplyDate,) => qspost('/index/Entrynotice/entryNoticePartTime', {
  offerId,
  xingming,
  lishubumen,
  zhiweimingcheng,
  zhijieshangji,
  LeaderWechat,
  LeaderMobile,
  ruzhiriqi,
  ConsultantWorkLocation,
  ConsultantReplyDate,
})


/*
 * 合同或者协议的详情
 * @constructor jack
 * */
export const protocolShow = (offerId, type) => qspost('/index/Protocol/protocolShow', {offerId, type})


/*
 * 合同或者协议 附件上传
 * @constructor jack
 * */
export const replyProtocolFile = (offerId, type, file, fileTwo) => qspost('/index/Protocol/replyEntryNoticeFile', {
  offerId,
  type,
  file,
  fileTwo
})


/*
 * 全职员工 劳动合同 新增、修改
 * @constructor jack
 * */
export const staffFullTimeProtocol = (offerId, contractNo, xingming, xingbie, shenfenzhenghao, contractSignDate, contractType, contractStartDate, contractEndDate, nonFixedTermStartDate, workStartDate, workEndDate, probationStartDate, probationEndDate, ruzhiriqi, gangweimingcheng, gongzuodidian, workType, jibenyuexin, shiyongqigongzi, paymentType, appendixNubmer, cASignDate, qianshunianxian) => qspost('/index/Protocol/staffFullTimeProtocol', {
  offerId,
  contractNo,
  xingming,
  xingbie,
  shenfenzhenghao,
  contractSignDate,
  contractType,
  contractStartDate,
  contractEndDate,
  nonFixedTermStartDate,
  workStartDate,
  workEndDate,
  probationStartDate,
  probationEndDate,
  ruzhiriqi,
  gangweimingcheng,
  gongzuodidian,
  workType,
  jibenyuexin,
  shiyongqigongzi,
  paymentType,
  appendixNubmer,
  cASignDate,
  qianshunianxian,

})

/*
 * 实习生 劳动合 同新增、修改
 * @constructor jack
 * */
export const staffPracticeProtocol = (offerId, xingming, xingbie, biyeyuanxiao, zaixiaozhuanye, shenfenzhenghao, shoujihao, youxiang, xianjudizhi, jinjirenxingming, yubenrenguanxi, jinjirendianhua, internMailAddress, internMailCode, gangweimingcheng, shixizhouqi, internJoinDate, internResignDate, gongzuofangshi, internWeeklyDays, InternContractSignDate,) => qspost('/index/Protocol/staffPracticeProtocol', {
  offerId,
  xingming,
  xingbie,
  biyeyuanxiao,
  zaixiaozhuanye,
  shenfenzhenghao,
  shoujihao,
  youxiang,
  xianjudizhi,
  jinjirenxingming,
  yubenrenguanxi,
  jinjirendianhua,
  internMailAddress,
  internMailCode,
  gangweimingcheng,
  shixizhouqi,
  internJoinDate,
  internResignDate,
  gongzuofangshi,
  internWeeklyDays,
  InternContractSignDate,

})

/*
 * 兼职员工 劳动合同 新增、修改
 * @constructor jack
 * */
export const staffPartTimeProtocol = (offerId,
                                      xingming,
                                      Gender,
                                      IDNo,
                                      InternMobile,
                                      InternEmail,
                                      InternAddress,
                                      InternEmergencyContact,
                                      InternRelationship,
                                      InternEmergencyMobile,
                                      InternMailAddress,
                                      InternMailCode,
                                      gangweimingcheng,
                                      Position,
                                      ConsultantJoinDate,
                                      ConsultantResignDate,
                                      ConsultantPeriod,
                                      ConsultantWorkType,
                                      gongzuomoshi,
                                      jianzhixinchou,
                                      daoqishijian,) => qspost('/index/Protocol/staffPartTimeProtocol', {
  offerId,
  xingming,
  Gender,
  IDNo,
  InternMobile,
  InternEmail,
  InternAddress,
  InternEmergencyContact,
  InternRelationship,
  InternEmergencyMobile,
  InternMailAddress,
  InternMailCode,
  gangweimingcheng,
  Position,
  ConsultantJoinDate,
  ConsultantResignDate,
  ConsultantPeriod,
  ConsultantWorkType,
  gongzuomoshi,
  jianzhixinchou,
  daoqishijian,

})





