import {qspost} from '@/plugins/axios'

/*
 * 员工列表
 * @constructor jack
 * */
export const staffList = (page, limit, searchName, staff_type, zaizhilizhi = '') => qspost('index/Staff/staffList', {
    page,
    limit,
    searchName,
    staff_type,
    zaizhilizhi
})

/*
 * 员工详情
 * @constructor jack
 * */
export const getStaffShow = (id) => qspost('index/Staff/getStaffShow', {id})

/*
 * 修改员工信息
 * @constructor jack
 * */
export const setStaffShow = (id) => qspost('index/Staff/setStaffShow', {id})


/*
 * 员工点击offter 同意或拒绝
 * @constructor jack
 * */
export const setOfferState = (offerId, offerState) => qspost('index/Offer/setOfferState', {offerId, offerState})

/*
 * 获取offter 的发送机构
 * @constructor jack
 * */
export const getSomeDateUseOfferId = (offerId) => qspost('index/Offer/getSomeDateUseOfferId', {offerId})


/*
 * 完善 全职 员工信息
 * @constructor jack
 * */
export const perfectData = (offerId, type, xingming, nicheng, xingbie, shoujihao, chushengnianyueri, shenfenzhenghao, youxiang, jiatingdizhi, youbian,weixinhao, biyeyuanxiao, zaixiaozhuanye, zuigaoxueli, biyeshijian, xianjudizhi, zuizaoruzhishijian, jinjirenxingming, jinjirendianhua, yubenrenguanxi, kaihuhang, zhihangmingcheng, yinhangkahao) => qspost('/index/Perfectbase/perfectData', {
    offerId,
    type,
    xingming,
    nicheng,
    xingbie,
    shoujihao, chushengnianyueri,
    shenfenzhenghao,
    youxiang,
    jiatingdizhi,youbian,
    weixinhao,
    biyeyuanxiao,
    zaixiaozhuanye,
    zuigaoxueli,
    biyeshijian,
    xianjudizhi,
    zuizaoruzhishijian,
    jinjirenxingming,
    jinjirendianhua,
    yubenrenguanxi,
    kaihuhang,
    zhihangmingcheng,
    yinhangkahao
})
