import { render, staticRenderFns } from "./practiceTime.vue?vue&type=template&id=268e8f3d&scoped=true&"
import script from "./practiceTime.vue?vue&type=script&setup=true&lang=js&"
export * from "./practiceTime.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268e8f3d",
  null
  
)

export default component.exports