<template>
  <div class="wrapper padding-lr-sm">

    <fullTime v-if="type === 1"></fullTime>
    <practiceTime v-if="type === 2"></practiceTime>
    <partTime v-if="type === 3"></partTime>

  </div>
</template>

<script>
import {getStaffShow} from "@/api/staff";
import { shangJiList} from "@/api/entry";
import fullTime from "@/views/admin/staffList/components/fullTime.vue";
import practiceTime from "@/views/admin/staffList/components/practiceTime.vue";
import partTime from "@/views/admin/staffList/components/partTime.vue";

export default {
  name: "staffDetail",
  data() {
    return {
      type: 1,
      offerId: '',

    }
  },
  components:{
    fullTime,
    practiceTime,
    partTime,
  },
  computed: {
    token() {
      return this.$store.state.token
    },
  },
  created() {
    this.$emit("setIndex", '/admin/staffList', "员工信息", true);
    this.id = this.$route.params.id
    this.getStaffShow()
    this.shangJiList()
  },
  methods: {
    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },
    async getStaffShow() {
      const res = await getStaffShow(this.id)
      this.type = res.data.offerSendData.leixing
      this.offerId = res.data.offerSendData.offer_id

    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
</style>
<script setup>
</script>
