export const wayOfWorking = [
    {value:1,label:'线上'},
    {value:2,label:'线下'},
    {value:3,label:'线上+线下'},
]
export const workingMode = [
    {value:1,label:'工作时长制'},
    {value:2,label:'任务目标制'},
]

//  性别
export const sexList = [
    {value: 2, label: '女'},
    {value: 1, label: '男'},
]
//  合同类型
export const contractTypeList = [
    {value: 1, label: '固定期限合同'},
    {value: 2, label: '无固定期限合同'},
    {value: 3, label: '工作任务期限合同'},
]
//  工时制
export const workTypeList = [
    {value: 1, label: '标准工时工作制'},
    {value: 2, label: '综合计算工时工作制'},
    {value: 3, label: '不定时工作制'},
]
//  工资形式
export const paymentTypeList = [
    {value: 1, label: '计时工资'},
    {value: 2, label: '计件工资'},
    {value: 3, label: '其他工资形式'},
]

//  离职原因
export const reasonList = [
    {value:1,label:'主动请辞'},
    {value:2,label:'合同到期员工放弃续约'},
    {value:3,label:'辞退'},
    {value:4,label:'其他'},
]

//  性别
export const yesOrNo = [
    {value: 1, label: '是'},
    {value: 2, label: '否'},

]
